import React from "react";
import logo from "../../assets/images/logo.svg";
// import Hero from "../../src/assets/images/visio_Wide_.avif";

import "./home.scss";

const Home = () => {
  
  return (
    <main className="mainHome">
      <section className="banner">
        <div className="bannerLogo">
          <img src={logo} alt="brand logo" />
        </div>
        {/* <img src={Hero} alt="vision labs" /> */}
        <div className="bannerContent">
          <h1>
            Digital <br />
            Conservation
          </h1>
        </div>
      </section>
      <section className="future">
        <div className="futureContent">
          <h2>
            Future proof your priceless art using the latest digital tools
          </h2>
          <a href="mailto:hello@visiolabs.com">Contact us now to learn more</a>
        </div>
      </section>
    </main>
  );
};

export default Home;
